import { LANGUAGE } from '~/enum/language'

export default {
  data: () => ({
    locale: '',
  }),
  created() {
    let locale = this.$i18n.locale
    switch (locale.toLowerCase()) {
      case LANGUAGE.ENGLISH:
        this.locale = 'en-us'
        break
      case LANGUAGE.SPANISH:
        this.locale = 'es-es'
        break
      default:
        this.locale = locale
    }
  },
}
