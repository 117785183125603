import { LANGUAGE } from '~/enum/language'
import locale from '~/mixins/locale'
import facebook from './icons/brands/facebook.svg'
import instagram from './icons/brands/instagram.svg'
import linkedIn from './icons/brands/linkedIn.svg'
import sparkle from './icons/brands/sparkle.svg'
import twitter from './icons/brands/twitter.svg'
import youTube from './icons/brands/youTube.svg'

import add from './icons/commons/add.svg'
import alertInstability from './icons/commons/alertInstability.svg'
import arrowDown from './icons/commons/arrowDown.svg'
import arrowLeft from './icons/commons/arrowLeft.svg'
import arrowRight from './icons/commons/arrowRight.svg'
import attachment from './icons/commons/attachment.svg'
import checkCircle from './icons/commons/checkCircle.svg'
import chevronDown from './icons/commons/chevronDown.svg'
import chevronRight from './icons/commons/chevronRight.svg'
import chevronUp from './icons/commons/chevronUp.svg'
import circleCheck from './icons/commons/circleCheck.svg'
import circleInfo from './icons/commons/circleInfo.svg'
import close from './icons/commons/close.svg'
import closeCircle from './icons/commons/closeCircle.svg'
import correctCircle from './icons/commons/correctCircle.svg'
import edit from './icons/commons/edit.svg'
import exit from './icons/commons/exit.svg'
import filledDown from './icons/commons/filledDown.svg'
import iconCheck from './icons/commons/iconCheck.svg'
import iconClose from './icons/commons/iconClose.svg'
import menuMobile from './icons/commons/menuMobile.svg'
import search from './icons/commons/search.svg'
import sparkles from './icons/commons/sparkles.svg'
import trash from './icons/commons/trash.svg'
import warningIcon from './icons/commons/warningIcon.svg'

import affiliateHelp from './icons/ilustrations/affiliateHelp.svg'
import affiliateHelpAr from './icons/ilustrations/affiliateHelpAr.svg'
import affiliateHelpJa from './icons/ilustrations/affiliateHelpJa.svg'
import articlesBanner from './icons/ilustrations/articlesBanner.svg'
import blog from './icons/ilustrations/blog.svg'
import buyersHelp from './icons/ilustrations/buyersHelp.svg'
import buyersHelpAr from './icons/ilustrations/buyersHelpAr.svg'
import buyersHelpJa from './icons/ilustrations/buyersHelpJa.svg'
import knowMore from './icons/ilustrations/knowMore.svg'
import knowMoreAr from './icons/ilustrations/knowMoreAr.svg'
import knowMoreJa from './icons/ilustrations/knowMoreJa.svg'
import producerHelp from './icons/ilustrations/producerHelp.svg'
import producerHelpAr from './icons/ilustrations/producerHelpAr.svg'
import producerHelpJa from './icons/ilustrations/producerHelpJa.svg'

export default {
  mixins: [locale],
  components: {
    facebook,
    twitter,
    linkedIn,
    youTube,
    instagram,
    sparkle,
    add,
    arrowDown,
    arrowLeft,
    arrowRight,
    attachment,
    close,
    checkCircle,
    chevronRight,
    chevronUp,
    chevronDown,
    circleInfo,
    circleCheck,
    filledDown,
    menuMobile,
    search,
    edit,
    exit,
    alertInstability,
    iconCheck,
    iconClose,
    correctCircle,
    warningIcon,
    closeCircle,
    affiliateHelp,
    articlesBanner,
    blog,
    buyersHelp,
    knowMore,
    producerHelp,
    affiliateHelpAr,
    buyersHelpAr,
    knowMoreAr,
    producerHelpAr,
    affiliateHelpJa,
    buyersHelpJa,
    knowMoreJa,
    producerHelpJa,
    trash,
    sparkles,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
  },

  computed: {
    component() {
      if ([LANGUAGE.ARABIC, LANGUAGE.JAPANESE].includes(this.locale)) {
        let newComponent = ''
        if (this.locale === LANGUAGE.ARABIC) {
          newComponent = `${this.name}Ar`
        } else {
          newComponent = `${this.name}Ja`
        }
        if (newComponent in this.$options.components) {
          return newComponent
        } else {
          return this.name
        }

        return
      }

      return this.name
    },
  },
}
